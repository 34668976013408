@import "../variables";
@import "../../bootstrap/mixins";

//
//	Non-A1 version
//
.hero {
	.hero-text {
		a {
			text-decoration: dotted underline 1px;
		}
	}

	.hero-note {
		a {
			font-weight: 700;
		}
	}

	.hero-awards-wrap {
		gap: $bu*.75 $bu*.75;
		flex-direction: row;

		@include media-breakpoint-up(sm) {
			gap: $bu*1.5 $bu*1.5;
		}

		@include media-breakpoint-down(lg) {
			justify-content: center;
		}

		@include media-breakpoint-down(sm) {
			.award-badges-wrap {
				flex-direction: row;
				align-items: center;
				gap: $bu*.75;

				.award-badge {
					flex-direction: column;

					.badge-text {
						text-align: center;
					}
				}
			}
		}
	}
}

.features-section {
	padding-top: $bu*4;

	@include media-breakpoint-up(lg) {
		padding-top: $bu*5;
	}
}

.awards-section {
	padding: $bu*3 0 $bu*5;

	@include media-breakpoint-up(lg) {
		padding: $bu*2.5 0 $bu*7;
	}

	.award-badges-wrap {
		&.centered {
			@include media-breakpoint-down(sm) {
				align-items: flex-start;
			}
		}

		&.size-lg {
			.badge-text {
				@include media-breakpoint-down(md) {
					max-width: 236px;
				}
			}
		}
	}
}

.carousel-media {
	@include media-breakpoint-down(sm) {
		.media-text {
			.media-body {
				order: 1;
			}
	
			.row {
				order: 2;
			}
	
			.img-mobile-wrap {
				margin-bottom: 0;
			}
		}
	}
}


//
//	A1 version
//
.hero {
	&.custom-a1 {
		padding-top: $bu*1.5;
		padding-bottom: $bu*2.5;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*3;
			padding-bottom: $bu*3.5;
		}

		.hero-text {
			@include media-breakpoint-between(sm,md) {
				margin-top: $bu*0.75;
			}
		}

		.award-badges-wrap {
			@include media-breakpoint-down(xl) {
				justify-content: flex-end;
			}
			@include media-breakpoint-between(md,xl) {
				justify-content: center;
			}

			@include media-breakpoint-down(sm) {
				align-items: flex-end;
			}

			.award-custom {
				@include media-breakpoint-between(xl,xl2) {
					display: none;
				}

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}

		.h0 {
			font-size:  $bu*2.625;
			line-height: $bu*3.375;
			font-weight: 750;

			@include media-breakpoint-up(sm) {
				font-size: $bu*4.875;
				line-height: $bu*4.75;
			}

			@include media-breakpoint-up(md) {
				font-size: $bu*6.1875;
				line-height: $bu*5.75;
			}

			@include media-breakpoint-up(lg) {
				font-size: $bu*8.375;
				line-height: $bu*7.75;
			}

			@include media-breakpoint-up(xl) {
				font-size: $bu*10.75;
				line-height: 159px;
			}
		}

		h1 {
			font-size:  $bu*1.125;
			line-height: $bu*1.5;

			@include media-breakpoint-up(sm) {
				font-size:  $bu*2.125;
				line-height: $bu*2.75;
			}

			@include media-breakpoint-up(md) {
				font-size:  $bu*2.75;
				line-height: $bu*3.375;
			}

			@include media-breakpoint-up(lg) {
				font-size:  $bu*3.5;
				line-height: $bu*4.5;
			}

			@include media-breakpoint-up(xl) {
				font-size:  $bu*4.75;
				line-height: $bu*5.875;
			}

			&:after {
				content: '';
				transform: translateX(-50%);
				background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-extra-sm.svg') no-repeat center;
				background-size: contain;
				display: inline-block;
				position: relative;
				width: 320px;
				height: 120px;
				left: 50%;
				bottom: 0;

				@media only screen and (min-width: 500px) and (max-width: 576px) {
					left: 40%;
				}

				@include media-breakpoint-up(sm) {
					position: absolute;
					background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-sm.svg') no-repeat center;
					width: 508px;
					height: 128px;
					left: 50%;
					bottom: -8px;
				}

				@include media-breakpoint-up(md) {
					background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-md.svg') no-repeat center;
					width: 688px;
					height: 160px;
					left: 50%;
					bottom: -14px;
				}

				@include media-breakpoint-up(lg) {
					background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-lg.svg') no-repeat center;
					width: 928px;
					height: 204px;
					left: 50.1%;
					bottom: -8px;
				}

				@include media-breakpoint-up(xl) {
					background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-xl.svg') no-repeat center;
					width: 1360px;
					height: 304px;
					bottom: -18px;
					left: 50%;
				}

				@include media-breakpoint-up(xl2) {
					background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-xl.svg') no-repeat center;
					width: 1360px;
					height: 304px;
					bottom: -18px;
					left: 50%;
				}
			}
		}

		.award-badges-wrap {
			&.size-lg {
				&.no-text {
					@include media-breakpoint-up(xl) {
						gap: $bu*1.5;
					}
				}
			}
		}
	}
}

.stripe-section {
	background: linear-gradient($gray10, $gray10 50%, $white 50%);
}

#avast-one-platinum {
	padding-top: $bu*5;

	@include media-breakpoint-up(lg) {
		padding-top: $bu*7;
	}

	h2 {
		@include media-breakpoint-down(md) {
			margin-bottom: $bu*4;
		}
	}
}

.reimbursement {
	padding: 0 0 $bu*5;

	@include media-breakpoint-up(lg) {
		padding: $bu*1.5 0 $bu*7;
	}
}

.media-platinum {
	.container {
		padding-top: 0;
	}
}

.signpost-section {
	.pc-links {
		a:first-of-type {
			padding-right: $base-unit*2;
		}

		a:not(:first-of-type) {
			position: relative;

			&:before {
				position: absolute;
				left: -21px;
				top: -2px;
				content: "\2022";
			}
		}
	}
}

.about-avast {
	.container {
		padding-top: $bu*8;
		padding-bottom: $bu*8;
		background: linear-gradient(0deg, rgba(7, 29, 43, 0.4), rgba(7, 29, 43, 0.4)), url("../../../../i/v3/aim/img/index/img-about-mobile.png");
		background-position: center top;
		background-size: cover;

		@include media-breakpoint-up(lg) {
			padding-top: $bu*16;
			padding-bottom: $bu*16;
		}

		@include media-breakpoint-up(sm) {
			border-radius: $bu*3;
		}
	}
}


//
//	DE differencies - hero A1
//
.mod-de-de,
.mod-de-ch {
	.hero {
		&.custom-a1 {
			.h0 {
				font-size:  $bu*2.875;
				line-height: $bu*2.625;

				@include media-breakpoint-up(sm) {
					font-size: $bu*4;
					line-height: $bu*4;
				}

				@include media-breakpoint-up(md) {
					font-size: $bu*5.375;
					line-height: $bu*5.375;
				}

				@include media-breakpoint-up(lg) {
					font-size: $bu*7.25;
					line-height: $bu*7.25;
				}

				@include media-breakpoint-up(xl) {
					font-size: $bu*9.25;
					line-height: $bu*9.25;
				}
			}

			h1 {
				font-size:  $bu*1.25;
				line-height: $bu*1.625;

				@include media-breakpoint-up(sm) {
				font-size:  $bu*1.75;
				line-height: $bu*2.25;
			}

				@include media-breakpoint-up(md) {
					font-size:  $bu*2;
					line-height: $bu*2.625;
				}

				@include media-breakpoint-up(lg) {
					font-size:  $bu*2.75;
					line-height: $bu*3.5;
				}

				@include media-breakpoint-up(xl) {
					font-size:  $bu*3.5;
					line-height: $bu*4.5;
				}

				&:after {
				background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-extra-sm-de-de.svg') no-repeat center;
				width: 320px;
				height: 120px;
				bottom: 0;
				left: 50%;

				@media only screen and (min-width: 500px) and (max-width: 576px) {
					left: 40%;
				}

					@include media-breakpoint-up(sm) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-sm-de-de.svg') no-repeat center;
						width: 576px;
						height: 96px;
						bottom: 36px;
						left: 50%;
					}

					@include media-breakpoint-up(md) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-md-de-de.svg') no-repeat center;
						width: 774px;
						height: 152px;
						bottom: 8px;
						left: 50%;
					}

					@include media-breakpoint-up(lg) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-lg-de-de.svg') no-repeat center;
						width: 1040px;
						height: 200px;
						bottom: 2px;
						left: 50%;
					}

					@include media-breakpoint-up(xl) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-xl-de-de.svg') no-repeat center;
						width: 1360px;
						height: 240px;
						bottom: 10px;
						left: 50%;
					}
				}
			}
		}
	}
}


//
//	FR differencies - hero A1 - Dog illustration
//
/*
.mod-fr-fr {
	.hero {
		&.custom-a1 {
			padding-bottom: $bu*2.5;

			.hero-text {
				@include media-breakpoint-down(sm) {
					margin-top: $bu*1.5;
				}

				@include media-breakpoint-between(sm,lg) {
					margin-top: $bu*0.75;
				}

				@include media-breakpoint-up(lg) {
					margin-top: $bu*1.5
				}

				@include media-breakpoint-up(xl2) {
					margin-top: $bu*2.5
				}
			}

			h1 {
				font-size:  $bu*2.8125;
				line-height: $bu*2.875;

				@include media-breakpoint-up(sm) {
					font-size:  $bu*4;
					line-height: $bu*3.875;
				}

				@include media-breakpoint-up(md) {
					font-size:  $bu*5.5;
					line-height: $bu*5.375;
				}

				@include media-breakpoint-up(lg) {
					font-size:  $bu*6.375;
					line-height: $bu*6.25;
				}

				@include media-breakpoint-up(xl) {
					font-size:  $bu*8.75;
					line-height: $bu*8;
				}

				&:after {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-extra-sm-fr-fr.svg') no-repeat center;
						width: 320px;
						height: 246px;
						bottom: 0;
						left: 50%;
						margin-top: -117px;

				@media only screen and (min-width: 500px) and (max-width: 576px) {
					left: 40%;
				}

					@include media-breakpoint-up(sm) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-sm-fr-fr.svg') no-repeat center;
						width: 508px;
						height: 110px;
						bottom: 38px;
						left: 50%;
						margin-top: auto;
					}

					@include media-breakpoint-up(md) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-md-fr-fr.svg') no-repeat center;
						width: 688px;
						height: 150px;
						bottom: 55px;
						left: 50%;
					}

					@include media-breakpoint-up(lg) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-lg-fr-fr.svg') no-repeat center;
						width: 928px;
						height: 170px;
						bottom: 62px;
						left: 50%;
					}

					@include media-breakpoint-up(xl) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-xl-fr-fr.svg') no-repeat center;
						width: 1192px;
						height: 206px;
						bottom: 80px;
						left: 50%;
					}
				}
			}
		}
	}
}
*/

.mod-fr-fr,
.mod-fr-ca,
.mod-fr-ch {
	.hero {
		 &.custom-a1 {
			.h0 {
				font-size: $bu*2.625;
				line-height: $bu*3.375;

				@include media-breakpoint-up(sm) {
					font-size: $bu*4;
					line-height: $bu*4;
				}

				@include media-breakpoint-up(md) {
					font-size: $bu*5.375;
					line-height: $bu*5.375;
				}

				@include media-breakpoint-up(lg) {
					font-size: $bu*7.25;
					line-height: $bu*7.25;
				}

				@include media-breakpoint-up(xl) {
					font-size: $bu*9.25;
					line-height: $bu*9.25;
				}
			}

			h1 {
				font-size: $bu*1.125;
				line-height: $bu*1.5;

				@include media-breakpoint-up(sm) {
					font-size: $bu*1.75;
					line-height: $bu*2.25;
				}

				@include media-breakpoint-up(md) {
					font-size: $bu*2;
					line-height: $bu*2.625;
				}

				@include media-breakpoint-up(lg) {
					font-size:  $bu*2.75;
					line-height: $bu*3.5;
				}

				@include media-breakpoint-up(xl) {
					font-size:  $bu*3.5;
					line-height: $bu*4.5;
				}

				&:after {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-extra-sm-fr-ca.svg') no-repeat center;
						width: 320px;
						height: 120px;
						bottom: 0;
						left: 50%;

				@media only screen and (min-width: 500px) and (max-width: 576px) {
					left: 40%;
				}

					@include media-breakpoint-up(sm) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-sm-fr-ca.svg') no-repeat center;
						width: 576px;
						height: 136px;
						bottom: -5px;
						left: 50%;
					}

					@include media-breakpoint-up(md) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-md-fr-ca.svg') no-repeat center;
						width: 792px;
						height: 168px;
						bottom: -10px;
						left: 50%;
					}

					@include media-breakpoint-up(lg) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-lg-fr-ca.svg') no-repeat center;
						width: 1072px;
						height: 212px;
						bottom: -10px;
						left: 50%;
					}

					@include media-breakpoint-up(xl) {
						background: url('../../../../i/v3/aim/img/index/hero-a1/hero-illustration-shield-xl-fr-ca.svg') no-repeat center;
						width: 1360px;
						height: 264px;
						bottom: -15px;
						left: 50%;
					}
				}
			}

			.btn.theme-gold {
				@include media-breakpoint-up(sm) {
					margin-left: $bu;
				}
			}
		}
	}
}


//
//	Japanese differencies
//
.mod-ja-jp {
	.hero {
		@include media-breakpoint-down(lg) {
			padding-bottom: $bu*4;
		}
	}

	.stripe {
		.award-badges-wrap {
			&.size-md {
				.badge-text {
					@include media-breakpoint-up(lg) {
						max-width: 336px;
					}
				}
			}
		}

		&.box {
			padding-top: $bu*3;
			padding-bottom: $bu*3;

			@include media-breakpoint-up(lg) {
				padding-top: $bu*2.5;
				padding-bottom: $bu*2.5;
			}
		}
	}
}

//
//	DE differencies - highlights on mobile
//
.mod-de-de,
.mod-de-ch,
.mod-ar-sa,
.mod-ar-ww {
	@include media-breakpoint-down(xl) {
		.highlight:before {
			display: none;
		}
		.highlight {
			white-space: normal;
		}
	}
}


//	––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
//	––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
//
//		GLOWEB-8096 – Live Graph Counter
//
//	––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––
//	––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––

//
//	Counter – Component – DS3
//
.gloweb-8096 {
	.cmp-counter {
		position: relative;	// reference for <strong> and <cmp-counter__graph>
		overflow: hidden;	// cropp the <cmp-counter__graph>

		max-width: $bu * 20; // 320px; set max-width (to keep the graph 100% width of the Tile)
		height: $bu * 8;	// 128px
		padding: $bu;
		border-radius: $bu * 1.5;

		// align the Tiles to the center
		@include media-breakpoint-down(sm) {
			margin: 0 auto;
		}

		@include media-breakpoint-between(lg, xl) {
			// Fix the tiles aligned to the middle of the Page. TODO (optional): This could be solved better?
			&#cmp-counter--seconds,
			&#cmp-counter--days { margin-left: auto; }
		}

		//
		//	The Tile content (text and buttons)
		//
		&__content {
			position: relative;
			z-index: 1;	// show the Tile content above the absolutely positioned Graph
		}


		//
		//	The Graph container
		//
		&__graph {
			position: absolute;
			left: 0;
			bottom: -5px;

			display: block;
			width: 100%;
			min-height: 100px !important;	// Overwrite the value set by ApexCharts JS on the HTML element

			//
			// <svg> element wrapper (created by ApexCharts.js)
			//
			.apexcharts-canvas {
				bottom: -40px;
				left: 0;
			}

			// <g> element inside the <svg> element. The Graph itself is in this SVG group
			.apexcharts-inner {
				transform: translate(0, 0);	// Overwrite defaults
			}

			//
			// Graph generated by ApexChart – overwrite the default styles
			//
			svg {
				// Hide X-axis grid lines
				// this is ugly, don't judge me! :)
				.apexcharts-xaxis,
				.apexcharts-xaxis-tick {
					display: none;	// TODO 👉 hide this within ApexChart JS config ❌ for now, no success
				}
			}
		}


		//
		// Loading Spinner
		//
		&__spinner {
			width: 100%;
			height: 100%;

			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;

			background: url('../../../../i/v3/components/gifs/spinner-light.gif') no-repeat center center;
		}

		// Tile title – number of attacs
		h4 {
			z-index: 2;	// temporary FIX: this will make possible to click on the NumberOfAttacs and trigger the CountUp function {…………}

			// the number of attacts – dynamic value (updated by JavaScript)
			.cmp-counter__number {
				display: inline-block;
			}

			// adjust icon position
			img {
				position: relative;
				top: -3px;

				@include media-breakpoint-down(lg) {
					width: $bu * 1.5;
					height: $bu * 1.5;
					left: -1px;
				}

				@include media-breakpoint-up(lg) {
					left: -3px;
				}
			}
		}

		// Text with a Tooltip
		&__text {
			display: inline-block;
			padding-right: $bu * 1.5;	// reserved space for the absolutely positioned span.cmp-counter-tooltip-icon

			// adjust position to fit Figma design; for some reason there is a 5px gap between the title (h4) and this text
			position: relative;
			top: -5px;
			left: -1px;
		}

		.cmp-counter-tooltip-icon {
			// position: absolute;	// icon height influences text line heigh. This is the fix
			vertical-align: text-top;
			top: 0;
			display: inline-block;
			width: $bu;
			height: $bu;
			margin-left: $bu / 4;

			// TODO: replace this with Cenda's megical @mixin or something like that
			background-image: url('../../../../i/v3/components/icons/sprites/icons-16.svg?v2');
			background-repeat: no-repeat;
			background-position: -256px -64px;

			// Recolor the icon
			// TODO: replace this with Cenda's megical @mixin or something like that
			filter: invert(30%) sepia(4%) saturate(4518%) hue-rotate(160deg) brightness(99%) contrast(89%);

			@include media-breakpoint-up(lg) {
				top: 2px; // align (horizontally) the icon with the text
			}
		}
	}
}


//
//	Animation - fade-out the Spinner => the Tile content will be visible
//
.gloweb-8096 {
	.cmp-counter {
		.cmp-counter__spinner {
			opacity: 1;	// Define the initial state
			// 🟥 A C H T U N G 🟥 If you change the transition speed, change it also in index.js
			transition: opacity 444ms ease-in;	// Speed of the fade-out animation
		}

		// Hide the Spinner
		&.js-show-content {
			.cmp-counter__spinner { opacity: 0; }
		}

		// The Spinner is completely hidden. Decrease z-index so the Tile content is accessible by mouse
		&.js-animation-completed {
			.cmp-counter__spinner { display: none; }
		}
	}
}


//
//	E X C E P T I O N S
//
.gloweb-8096 {
	//...

	//
	// Box No. 1 – Blocked attacks per S E C O N D
	//
	#cmp-counter--seconds {
		.cmp-counter__number {
			width: 38px;

			@include media-breakpoint-up(lg) {
				width: 47px;
			}
		}

		// TODO: hide spinner once the graph is loaded (👉 add class "js-graph-rendering-completed")
		.cmp-counter__graph::before {
			display: none !important;
		}
	}

	//
	// Box No. 2 – Blocked attacks per H O U R
	//
	#cmp-counter--hours {
		.cmp-counter__number {
			width: 82px;

			@include media-breakpoint-up(lg) {
				width: 110px;
			}
		}
	}

	//
	// Box No. 3 – Blocked attacks per D A Y
	//
	#cmp-counter--days {
		.cmp-counter__number {
			width: 110px;

			@include media-breakpoint-up(lg) {
				width: 146px;
			}
		}
	}

	//
	// Box No. 4 – Customers protected worldwide
	//
	#cmp-counter--worldwide {
		h4 {
			img {
				@include media-breakpoint-down(lg) { left: 0; }
				@include media-breakpoint-up(lg) { left: 1px; }
			}
		}

		.btn {
			position: relative;
			top: -3px;
		}
	}
}


// Hero section –– EN-US –– has a gray background...
.mod-en-us {
	.gloweb-8096 {
		&.cmp-counter__section {
			position: relative;	// context for positioning the Background

			@include media-breakpoint-down(xl) {
				padding-top: $bu * 1.5;
				padding-bottom: $bu * 2;
			}

			// create the gray Background
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 100%;

				position: absolute;
				top: 0;
				left: 0;

				background: $gray10;

				// Adjust Background height (50% height of the Tile)
				@include media-breakpoint-up(xl) {
					height: 50%;
				}
			}
		}
	}
}


//
//	🔥 HOTFIX
//
.tooltip {
	// FIX: Tooltip flickering issue – https://stackoverflow.com/questions/14326724/twitter-bootstrap-tooltip-flickers-when-placed-on-top-of-button-but-works-fine
	// TODO 👉 implemented this on Design System level
	pointer-events: none;
}



//
//	Jumpshot – Banner –– GLOWEB-8731
//
.jumpshot-banner {
	display: none;	// Banner will be shown via JS (only on first visit)

	position: relative;
	padding-inline-start: $bu * 3;
	padding-inline-end: $bu * 3;

	border-width: 0 0 1px 0;
	border-radius: 0;

	@include media-breakpoint-down(lg) {
		padding-inline-start: $bu;
		padding-inline-end: $bu * 2.75;
	}

	&__text {
		@include media-breakpoint-down(lg) {
			font-size: $body-3-font-size-m;
			line-height: $body-3-line-height-m;
		}

		@include media-breakpoint-up(lg) {
			text-align: center;
		}
	}

	.button-circle {
		position: absolute;
		top: $bu * .5;
		right: $bu * .5;

		@include media-breakpoint-up(lg) {
			top: $bu * .5;
			right: $bu * .5;
		}
	}
}